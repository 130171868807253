<template>
  <v-col cols="12" sm="9">
    <h1 class="">Create staff account</h1>
    <span class="mb-3 d-inline-block" for="">
      You've been invited to create a staff account with test account. Finalize your account details to gain your access
      to test account.
    </span>
    <Notification v-if="errorMessage.length > 0" :messages="errorMessage" type="error" />
    <div><InputDefault fieldName="Email" label="Email" :isValidate="true" :model="['data', 'email']" /></div>
    <div class=""><InputDefault label="Frist Name" :model="['data', 'fristName']" /></div>
    <div class="mt-5"><InputDefault label="Last Name" :model="['data', 'lastName']" /></div>
    <div class="mt-5"><InputDefault label="Phone" :model="['data', 'phone']" /></div>
    <div class="form-component mt-3">
      <label class="fz-14">
        Password
      </label>
      <v-text-field
        @input="$v.$touch()"
        :error-messages="$v.password.$dirty && !$v.password.required ? $t('Password is required') : ''"
        v-model="password"
        type="password"
        aria-autocomplete="off"
      ></v-text-field>
    </div>
    <div class=" form-component">
      <label class="fz-14">
        Confirm Password
      </label>
      <v-text-field
        @input="$v.$touch()"
        :error-messages="
          $v.confirmPassword.$dirty && !$v.confirmPassword.required
            ? $t('Confirm password  is required')
            : $v.confirmPassword.$dirty && !$v.confirmPassword.sameAsPassword
            ? $t('Confirm password doesnt match ')
            : ''
        "
        v-model="confirmPassword"
        type="password"
        aria-autocomplete="off"
      ></v-text-field>
    </div>
    <div class="mt-5">
      <v-btn @click="onSignup" :loading="isLoading" width="100%" color="primary">
        Sign up
      </v-btn>
    </div>
    <!-- <div class="label-forgot mt-5">
      <span @click="$router.push({ path: '/auth/login' })">Already have an account? Sign in</span>
    </div> -->
  </v-col>
</template>

<script>
import { required, minLength, maxLength, email, helpers, sameAs } from 'vuelidate/lib/validators';
const alpha = helpers.regex('alpha', /^[a-zA-Z0-9]*$/);
import { authService } from '@/apis/auth.s';
import STORAGE_NAME from '@/const/storage';
import Notification from '@/components/Notification';
import { mapMutations } from 'vuex';

export default {
  props: ['token'],
  components: {
    Notification,
  },
  data() {
    return {
      type: 'password',
      isLoading: false,
      data: {
        email: '',
        firstName: '',
        lastName: '',
        phone: '',
      },
      password: '',
      confirmPassword: '',

      invalid: false,
      errorMessage: [],
    };
  },
  validations: {
    confirmPassword: {
      required,
      sameAsPassword: sameAs('password'),
    },
    password: {
      required,
    },
    data: {
      email: {
        email,
        required,
      },

      //   phone:{

      //   }
      //   password: {},
    },
  },
  methods: {
    /*
    Fnc Signup
    */
    async onSignup() {
      this.$v.$touch();
      try {
        if (!this.$v.$invalid) {
          let response = await authService.registerV2({
            ...this.data,
            password: this.password,
          });
          window.localStorage.setItem(STORAGE_NAME.ACCESS_TOKEN, response.data.accessToken || '');
          window.localStorage.setItem(STORAGE_NAME.REFRESH_TOKEN, response.data.refreshToken || '');
          window.localStorage.setItem(STORAGE_NAME.STORE_ID, response.data.storeId || '');
          this.$router.push('/auth/shop');
        }
      } catch (error) {
        this.errorMessage = error;
        //empty
      }
    },
  },
  async created() {
    // router  auth/accept?t=

    if (this.$route.query && this.$route.query.access_token && this.$route.query.invitation_token) {
      let response = await authService.checkStaffAvalable(
        this.$route.query.access_token,
        this.$route.query.invitation_token,
      );

      if (response.data) {
        this.$router.push({
          name: 'auth',
          params: {
            authPage: 'login',
          },
        });
      } else {
        this.data.email = this.$route.query.staff_email || '';
      }
    } else {
      this.$router.push({ path: '/auth/login' });
    }
  },
};
</script>
<style lang="scss" scoped>
h1 {
  font-size: 3.2rem;
}
.show-password {
  cursor: pointer !important;
  margin-right: 5px;
  margin-top: 5px;
}
.label-forgot span {
  cursor: pointer;
}
</style>
